@use "sass:math";
@use '~@THC/styles/modifiers';

.closeButton {
	z-index: 100;

	&:focus-visible {
		@include modifiers.focusRing();
	}

	&:global(.MuiIconButton-root) {
		color: #9e9e9e;
		position: absolute;
		right: 16px;
		top: 16px;
	}
}

.actions {
	&:global(.MuiDialogActions-root) {
		padding: 32px 0 0 0;
		justify-content: space-between;
		gap: 16px;
	}

	&.vertical {
		flex-direction: column;
	}
}
