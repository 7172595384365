@use '~@THC/styles/color';

$items: 5;

@mixin col-x-paddings {
	@for $i from 1 through $items {
		&.col-pad-l-#{$i} {
			padding-left: $i * 8px;
		}
		&.col-pad-r-#{$i} {
			padding-right: $i * 8px;
		}
	}
}

.MuiTable-root {
	tbody .MuiTableRow-root {
		td.data-table-date.MuiTableCell-root {
			white-space: pre-line;
			min-width: 100px;
		}
		td.data-table-make.MuiTableCell-root {
			min-width: 130px;
		}
		td.data-table-model.MuiTableCell-root {
			min-width: 220px;
		}
		td.data-table-seller-name {
			min-width: 290px;
		}
		td.data-table-notes {
			min-width: 392px;
			word-break: break-all;
		}
		td.MuiTableCell-root {
			@include col-x-paddings;
		}
	}
	thead .MuiTableRow-root {
		th {
			@include col-x-paddings;
		}
	}
}

.table-divider {
	border-left: 2px solid color.$light-grey-3;
}

.actions-buttons {
	position: sticky;
	right: 10px;
	min-width: 280px;
	& > * {
		opacity: 0;
		display: flex;
	}
	& > div {
		align-items: center;
	}
}

.disabled-data-grid-cell.MuiTableCell-root {
	color: color.$light-grey-1;
}

.edit-cell {
	min-width: 120px;
}

.hidden-data-grid-cell {
	& > * {
		display: none;
	}
}
