@use "sass:math";
@use '~@THC/styles/color';

.button {
	width: 283px;
}

.buttonLink {
	height: 48px;
	margin: 10px 0;
}

.card {
	background-color: transparent;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 16%;
	min-height: calc(100vh - 96px);
	padding-bottom: 96px;
}

.wrapper {
	& + footer {
		display: none;
	}
	overflow: hidden;
	position: relative;
}

.title {
	color: color.$primary-black;
	font-weight: bold;
	margin-top: 0;
	font-size: 3rem;
	line-height: 3.5rem;
	margin-bottom: 24px;
}

.header {
	min-height: 96px;
	padding: 0 40px;
}

.separator {
	font-weight: bold;
	line-height: 48px;
	text-align: center;
	width: 283px;
}

.chevronBackground {
	&::after {
		background-color: color.$primary-yellow;
		content: '';
		display: block;
		height: 1500px;
		position: absolute;
		top: 50%;
		transform: rotate(30deg);
		transform-origin: top left;
		width: 400px;
	}
	&::before {
		background-color: color.$primary-yellow;
		bottom: 50%;
		content: '';
		display: block;
		height: 1500px;
		position: absolute;
		transform: rotate(-30deg);
		transform-origin: bottom left;
		width: 400px;
	}
	background-color: color.$primary-yellow;
	height: 100%;
	pointer-events: none;
	position: absolute;
	right: 0;
	width: 30%;
}
