@use "sass:math";
@use '~@THC/styles/color';
@use '~@THC/styles/core';
@use 'styles/table.scss';

$inherit-value: inherit;

:root {
	--table-buttons-container-width: 120px;
	--body-scroll: initial;
	--scrollbar-width: 0;
}

body {
	overflow: var(--body-scroll);
	font-size: 1rem;
}

h3 {
	display: block;
	font-size: 1.17em;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0;
	margin-inline-end: 0;
	font-weight: bold;
}

h4 {
	display: block;
	margin-block-start: 1.33em;
	margin-block-end: 1.33em;
	margin-inline-start: 0;
	margin-inline-end: 0;
	font-weight: bold;
}

h1 {
	font-size: 2em;
	margin-block-start: 0.67em;
	margin-block-end: 0.67em;
	margin-inline-start: 0;
	margin-inline-end: 0;
	font-weight: bold;
}

ol {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

[data-test=notification-message] {
	padding-top: 68px !important;
}

svg {
	fill: currentColor;
}

#main {
	min-height: 100vh;
	display: flex;
}

#main > div:first-child {
	min-height: calc(100vh - 288px);
	display: flex;
	z-index: 1;
	position: relative;
	flex-direction: column;
	flex-grow: 1;
	height: auto !important;
}

div[class*='--spacer-'] {
	display: none;
}

textarea[aria-hidden='true'] {
	min-height: calc(48px * 3); /* For textarea shadows */
	padding: calc(8px + 4px); /* For textarea shadows, including 2px border top/bottom */
	max-width: 328px;
}

*:focus {
	outline: none;
}

.table-wrapper {
	max-width: 100vw !important;
	position: relative !important;
	overflow-x: scroll !important;
	scroll-snap-type: x mandatory !important;
	-webkit-overflow-scrolling: touch;
}

.table-wrapper th {
	scroll-snap-align: end;
}

.table-wrapper tr td:last-child {
	position: -webkit-sticky;
	position: sticky;
	right: 0;
}

.table-wrapper tr td:last-child > * {
	max-width: var(--table-buttons-container-width);
	opacity: 0;
}

.table-wrapper tr:hover td:last-child > * {
	opacity: 1;
}

.table-wrapper::-webkit-scrollbar {
	display: none;
}

.table-wrapper .table-scroll {
	height: 18px;
	max-width: 100vw;
	overflow-x: scroll;
	overflow-y: hidden;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 10;
	width: 100%;
}

.table-wrapper .table-scroll.table-scroll-sticky {
	position: -webkit-sticky;
	position: sticky;
}

.table-wrapper .table-scroll > div {
	height: 1px;
}

.table-wrapper .table-scroll::-webkit-scrollbar {
	bottom: 0;
	height: 18px;
	position: fixed;
}

.table-wrapper .table-scroll::-webkit-scrollbar-thumb {
	background-color: color.$light-grey-1;
	border-radius: 0;
}

footer {
	background-color: color.$primary-black;
	color: color.$primary-white;
	display: flex;
	font-size: 14px;
	padding: 32px 40px;
}

body #main .multi-select-list {
	position: absolute;
	width: 296px;
	z-index: 1;
	top: 78px;
}

a:not(.mw-i),
.mw-link:not(.mw-i) {
	border-bottom: none;
}

p:not(.mw-i) {
	padding-bottom: 0;
}

.mw-tooltip-slider {
	margin-top: 0;
}

/* clears the ‘X’ from input type='search */
input[type='search']::-webkit-search-cancel-button {
	display: none;
}

.MuiTableRow-root:hover {
	.visible-on-hover {
		visibility: visible;
	}
}

.enquiry-page-section {
	width: 100%;
	padding-right: 64px !important;
	margin-bottom: 0 !important;
	position: relative;

	&:after {
		all: unset;
	}

	&.seller-documents {
		padding-right: 0 !important;
		max-width: 788px;
	}
}

.enquiry-page-loader {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: absolute;
	top: calc(50% - 16px);
}
